<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <li class="is-active"><a>{{ $t('breadcrumb_company_settings') }}</a></li>
            <router-link tag="li" :to="{ name: 'company-defaults', params: { cid } }"><a>{{ $t('breadcrumb_company_defaults') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-8" v-if="company">
      <div class="container">
        <h1 class="title is-3">{{ $t('company_account_week_settings') }}</h1>
        <h2 class="subtitle is-4">
          {{ $t('company_account_week_settings_help') }}
        </h2>
        <div class="columns py-8" v-if="company.weekSettings">
          <div class="column is-6-desktop">
            <h1 class="title is-5">{{ $t('account_week_settings') }}</h1>
            <h2 class="subtitle is-6">
              {{ $t('account_week_settings_help') }}
            </h2>
            <div class="field">
              <input-duration-time
                label="account_week_settings_due"
                :value="company.weekSettings.due"
                guess="hh"
                @change="
                  change({ group: 'weekSettings', field: 'due', value: $event })
                "
                :disabled="disabled"
              />
            </div>
            <div class="field">
              <input-duration-time
                label="account_week_settings_max"
                :value="company.weekSettings.max"
                @change="
                  change({ group: 'weekSettings', field: 'max', value: $event })
                "
                :disabled="disabled"
              />
              <div class="help">
                {{ $t('account_week_settings_max_helper') }}
              </div>
            </div>
            <div class="field">
              <input-duration-time
                label="account_week_settings_conv"
                guess="hh"
                :value="company.weekSettings.conv"
                @change="
                  change({
                    group: 'weekSettings',
                    field: 'conv',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
          </div>
          <div class="column is-6-desktop">
            <h1 class="title is-5">{{ $t('account_year_settings') }}</h1>
            <h2 class="subtitle is-6">
              {{ $t('account_year_settings_help') }}
            </h2>
            <div class="field">
              <input-day
                label="account_year_settings_vacations"
                :value="company.yearSettings.vacations"
                :min="0"
                :max="365"
                :step="1"
                @change="
                  change({
                    group: 'yearSettings',
                    field: 'vacations',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="px-2 md:px-0 py-8" v-if="company">
      <div class="container">
        <update-info :value="company" />
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { clone } from 'ramda';
import company from './company';

import CompanyName from '@/components/CompanyName';
import InputDay from '@/components/InputDay';
import InputDurationTime from '@/components/InputDurationTime';
import UpdateInfo from '@/components/UpdateInfo';

export default {
  name: 'CompanyDefaults',
  mixins: [company],
  components: { CompanyName, InputDay, InputDurationTime, UpdateInfo },
  computed: {
    ...mapGetters('perms', ['isManagerRW']),
    disabled() {
      return !this.isManagerRW;
    },
  },
  async beforeRouteLeave(from, to, next) {
    await this.send({ clear: true });
    next();
  },
  methods: {
    ...mapActions('company', ['fieldSet', 'send']),
    change({ group, field, value }) {
      const part = clone(this.company[group]);
      this.$set(part, field, value);
      this.fieldSet({ field: group, value: part });
    },
  },
};
</script>
